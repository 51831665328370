import SEO from '@bradgarropy/gatsby-plugin-seo'
import PostList from 'components/Blog/PostList'
import { usePosts } from 'hooks'
import { FC, useState } from 'react'

const BlogPage: FC = () => {
  const allPosts = usePosts()

  const [posts, setPosts] = useState(allPosts)

  return (
    <>
      <PostList posts={posts} />
    </>
  )
}

export default BlogPage
