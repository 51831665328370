import { FC } from 'react'
import { Link } from 'gatsby'
import { Post } from 'types/post'

import './PostList.scss'

type PostListProps = {
  posts: Post[]
}

const PostList: FC<PostListProps> = ({ posts }) => {
  if (!posts.length) {
    return (
      <div className="noPosts">
        <span>🤷🏼‍♂️</span> No Posts Found
      </div>
    )
  }

  return (
    <div className="post-list">
      {posts.map((post, index) => {
        const { date, title, slug } = post.frontmatter

        return (
          <div key={index}>
            <div className="postMeta">
              <p>{date}</p>
            </div>
            <div className="post-title">
              <h2>
                {slug ? <Link to={`/blog/${slug}`}>{title}</Link> : title}
              </h2>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PostList
