import { FC } from 'react'
import SEO from '@bradgarropy/gatsby-plugin-seo'
import Home from 'components/Pages/Home'

const HomePage: FC = () => {
  return (
    <>
      <SEO
        title="Hacking the JS Interview | Ace Your Frontend Interviews"
        facebook={{
          image: 'https://hackingthejsinterview.com/logo.png'
        }}
        twitter={{
          image: 'https://hackingthejsinterview.com/logo.png',
          card: 'summary_large_image'
        }}
        description={`It's time to crush those frontend interviews. Hacking the JS Interview is the place learn about how to ace your next technical job interview!`}
      />

      <Home />
    </>
  )
}

export default HomePage
